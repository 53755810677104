
// Header and Footer Quiz
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

@keyframes r-marquee {
    from { transform: translateX(-50%); }
    to { transform: translateX(0%); }
}

// Opacity
@keyframes kf-opacity{
    0%   {opacity: 0}
    100% {opacity: 1;}
}

@keyframes left-to-right{
    0% {left: -500px}
    100% {left: 0}
}

@keyframes right-to-left{
    0% {right: -1000px}
    100% {right: 0}
}